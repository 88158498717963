import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({element}) => (
	<SimpleReactLightbox>{element}</SimpleReactLightbox>
);

// false to display site normally
const maintenance = false;

export function onClientEntry() {
	if (maintenance && !window.location.pathname.startsWith('/maintenance')) {
		window.location = '/maintenance';
	}
}

export function onRouteUpdate() {
	if (maintenance && !window.location.pathname.startsWith('/maintenance')) {
		window.location = '/maintenance';
	}
}
